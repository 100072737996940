import singleMode from "./singleMode";

// urls can be maximum 2048 characters long
// Router
const urlSearchParams = new URLSearchParams(window.location.search);
const mode = urlSearchParams.get('mode');
const id = urlSearchParams.get('id');
window.locale = urlSearchParams.get('locale') || 'en';
document.documentElement.lang = window.locale;

console.log(id)
// language
// document.documentElement.lang
const languageButton = document.createElement('button');
languageButton.innerText = window.locale === 'en' ? 'it' : 'en';
languageButton.classList.add('language-button');
languageButton.addEventListener('click', () => {
    const url = new URL(window.location.href);
    url.searchParams.set('locale', window.locale === 'en' ? 'it' : 'en');
    // console.log(url.toString())
    location.href = url.toString();
});

const isPortrait = window.matchMedia("(orientation: portrait)");
const appendLanguageButton = () => {
    if (isPortrait.matches) {
        console.log('portrait');
        document.querySelector('#mobile-menu .mobile-menu-body')!.appendChild(languageButton);
    } else {
        document.body.appendChild(languageButton);
    }
}
appendLanguageButton();
window.matchMedia("(orientation: portrait)").addEventListener("change", () => {
    appendLanguageButton();
});

document.querySelectorAll('.info-button').forEach((button) => {
    console.log(button.dataset.target)
    button.addEventListener('click', (e) => {
        e.preventDefault();
        const target = document.querySelector(`#${button.dataset.target}`);
        target.showModal();
    });
});

// document.getElementById('info-humanity').showModal();


switch (mode) {
    case 'single': singleMode(); break;
    case 'preview':
        import('./previewMode').then((custom) => {
            custom.mount();
            document.body.classList.add('preview-mode');
        });
    break;
    case 'request':
        import('./RequestMode').then((custom) => {
            custom.mount();
            document.body.classList.add('preview-mode');
        });
    break;
    default:
        singleMode();
}
    


// append locale to every anchor
const anchors = document.querySelectorAll('a');
anchors.forEach((anchor) => {
    const url = new URL(anchor.href);
    url.searchParams.set('locale', window.locale);
    anchor.href = url.toString();
});
