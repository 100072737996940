import { EventManagerInterface } from "./eventManager";

export default class LocalEventManager implements EventManagerInterface {
    events: { [key: string]: ((data: any) => void)[]; } = {};
    subscribe(eventName: string, callback: (data: any) => void) {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }
        this.events[eventName].push(callback);
    }
    publish(eventName: string, data: any) {
        if (this.events[eventName]) {
            this.events[eventName].forEach(callback => callback(data));
        }
    }
    unsubscribe(eventName: string, callback: (data: any) => void) {
        if (this.events[eventName]) {
            this.events[eventName] = this.events[eventName].filter(cb => cb !== callback);
        }
    }
}
