import { EventManagerInterface } from "./eventManager";
import { tiledPictureOptions } from "./tiledPictureOptions";
import Encoder from "./Encoder";
const defaultLedImage = new URL('./image/04_CORNICE_APP_EMOJI_GIALLA.png', import.meta.url).href;
console.log(defaultLedImage);

export class TiledPicture {

    rows: number;
    cols: number;
    urls: string[];
    id: string;
    initialState: number[][] | null;
    tiles: {
        el: HTMLElement;
        state: number;
    }[][];
    eventManager: EventManagerInterface;
    showFrame: boolean = false;
    frameUrls?: Array<string | null>;
    frameEl?: HTMLElement;
    element: HTMLElement;

    constructor(options: tiledPictureOptions, eventManager: EventManagerInterface) {
        this.rows = options.rows;
        this.cols = options.cols;
        this.urls = options.urls;
        this.id = options.id;
        this.initialState = options.initialState || null;
        this.frameUrls = options.frameUrls;
        this.eventManager = eventManager;
    }

    setTileState(row: number, col: number, state: number) {
        if (this.tiles[row][col].state === state)
            return;
        this.tiles[row][col].state = state;
        this.tiles[row][col].el.dataset.state = state.toString();

        if (this.tiles[row][col].el.classList.contains('flip')) {
            (this.tiles[row][col].el.querySelector('.front') as HTMLElement).style.backgroundImage = `url(${this.urls[state]})`;
        } else {
            (this.tiles[row][col].el.querySelector('.back') as HTMLElement).style.backgroundImage = `url(${this.urls[state]})`;
        }

        this.tiles[row][col].el.classList.toggle('flip');
        // console.log(this.calculatePercentages());
    }
    /**
     * First byte representes the encoding version
     * Second byte represents the number of rows
     * Third byte represents the number of columns
     * The rest of the bytes represent the state of each tile
     * @returns a string representing the state of the tiled picture
     */
    encodeState(): string {
        const encoder = new Encoder({ id: this.id });
        encoder.encode(this.rows);
        encoder.encode(this.cols);
        for (let i = 0; i < this.rows; i++) {
            for (let j = 0; j < this.cols; j++) {
                encoder.encode(this.tiles[i][j].state);
            }
        }
        return encoder.value;
    }

    decodeState(state: string) {
        const encoder = new Encoder({ value: state, id: this.id });
        const rows = encoder.decode();
        const cols = encoder.decode();
        if (rows !== this.rows || cols !== this.cols) {
            throw new Error('State does not match');
        }
        for (let i = 0; i < this.rows; i++) {
            for (let j = 0; j < this.cols; j++) {
                this.setTileState(i, j, encoder.decode());
            }
        }
    }

    renderFrame () {
        for (let i = 0; i < this.rows; i++) {
            for (let j = 0; j < this.cols; j++) {
                const tile = document.createElement('div');
                tile.classList.add('frame-tile');
                tile.style.width = `${100 / this.cols}%`;
                tile.style.height = `${100 / this.rows}%`;
                tile.style.left = `${j * 100 / this.cols}%`;
                tile.style.top = `${i * 100 / this.rows}%`;
                let bg = `url(${defaultLedImage})`;
                if (this.frameUrls) {
                    if (this.frameUrls[this.tiles[i][j].state]) {
                        bg = `url(${this.frameUrls[this.tiles[i][j].state]})`;
                    }
                }
                tile.style.backgroundImage = bg;
                tile.style.backgroundPosition = `${-j * 100}% ${-i * 100}%`;
                tile.style.backgroundSize = `${this.cols * 100}% ${this.rows * 100}%`;
                if (this.frameEl) this.frameEl.appendChild(tile);
            }
        }
    }

    mount(element?: HTMLElement | string,) {
        if (element === undefined) {
            element = document.querySelector('#transhuman-app')! as HTMLElement;
        }
        if (typeof element === 'string') {
            element = document.querySelector(element)! as HTMLElement;
        }

        const container = document.createElement('div');
        container.className = 'tiled-picture-container';
        element.appendChild(container);

        if (this.showFrame) {
            this.frameEl = document.createElement('div');
            this.frameEl.className = 'frame';
            container.appendChild(this.frameEl);
        }

        const camera = document.createElement('div');
        camera.classList.add('camera');
        container.appendChild(camera);

        this.element = element;
        this.tiles = [];
        if (this.initialState === null) {
            this.initialState = [];
        }

        for (let i = 0; i < this.rows; i++) {
            this.tiles[i] = [];
            if (this.initialState[i] === undefined) {
                this.initialState[i] = [];
            }
            for (let j = 0; j < this.cols; j++) {
                if (this.initialState[i][j] === undefined) {
                    this.initialState[i][j] = 0;
                }
                const tile = document.createElement('div');
                tile.classList.add('tile');
                // tile.classList.add('flip');
                tile.style.width = `${100 / this.cols}%`;
                tile.style.height = `${100 / this.rows}%`;
                // TODO: fix brutto per la fiera
               /*  if (document.body.classList.contains('preview-mode')) {
                    if (i === 3) {
                        tile.style.height = `16.7667%`;
                    }
                } */
                tile.style.left = `${j * 100 / this.cols}%`;
                tile.style.top = `${i * 100 / this.rows}%`;
                tile.dataset.row = i.toString();
                tile.dataset.col = j.toString();
                tile.dataset.state = this.initialState[i][j].toString();
                tile.innerHTML = `
                    <figure class="right"></figure>
                    <figure class="left"></figure>
                    <figure class="top"></figure>
                    <figure class="bottom"></figure>
                    <figure
                        style="
                            background-image: url(${this.urls[0]});
                            background-position: ${-j * 100}% ${-i * 100}%;
                            background-size: ${this.cols * 100}% ${this.rows * 100}%;
                            "
                        class="front">
                    </figure>
                    <figure
                        style="
                            background-image: url(${this.urls[1]});
                            background-position: ${-j * 100}% ${-i * 100}%;
                            background-size: ${this.cols * 100}% ${this.rows * 100}%;
                            "
                        class="back">
                    </figure>
                `;
                this.tiles[i][j] = {
                    el: tile,
                    state: 0
                };
                camera.appendChild(tile);
                this.setTileState(i, j, this.initialState[i][j]);
                tile.addEventListener('mousedown', (e) => {
                    e.stopPropagation();
                    const state = (+tile.dataset.state! + 1) % this.urls.length;
                    this.eventManager.publish('tile', { row: +tile.dataset.row!, col: +tile.dataset.col!, state });
                });
            }
        }

        this.eventManager.subscribe('tile-change', (payload) => {
            if (Array.isArray(payload)) {
                payload.forEach((p, i) => {
                    setTimeout(() => { this.setTileState(p.row, p.col, p.state) }, i * 100);
                })
            } else {
                this.setTileState(payload.row, payload.col, parseInt(payload.state));
            }
        });

        window.addEventListener('resize', () => {
            this.setTileSize();
        });

        this.setTileSize();

    }

    setTileSize() {
        const container = this.element.querySelector('.tiled-picture-container') as HTMLElement;
        const camera = this.element.querySelector('.camera') as HTMLElement;
        const largerTile = Math.max(this.rows, this.cols);
        let tileSize = 100;
        const isPortrait = window.innerWidth < window.innerHeight;
        const isPreview = document.body.classList.contains('preview-mode');
        if (!isPortrait) {
            let smallerSide = Math.min(window.innerWidth, window.innerHeight) - 54;
            let tileSize = smallerSide / largerTile;

            if (isPreview) {
                container.style.height = `${tileSize * this.rows}px`;
                const sizeDifference = ((tileSize * this.cols) / 100) * 23;
                
                const width = (tileSize * this.rows) - sizeDifference;
                const height = (tileSize * this.cols) - sizeDifference;
                
                camera.style.height = `${width}px`;
                camera.style.width = `${height}px`;
            } else {
                camera.style.height = `${tileSize * this.rows}px`;
                camera.style.width = `${tileSize * this.cols}px`;
            }
            
        }
        if (isPortrait) {
            const smallerSide = Math.min(window.innerWidth, window.innerHeight);
            const tileSize = smallerSide / largerTile;

            // make width the first multiple of 4 that is smaller than the height
            const height = tileSize * this.rows;
            const width = tileSize * this.cols;
            /* const widthMultiple = Math.ceil(width / 4);
            const widthRemainder = width % 4;
            const widthMultiple4 = widthMultiple * 4;
            const heightMultiple = Math.ceil(height / 4);
            const heightRemainder = height % 4;
            const heightMultiple4 = heightMultiple * 4; */


            camera.style.height = `${height}px`;
            camera.style.width = `${width}px`;
        }
        this.element.style.setProperty('--tile-size', `${tileSize}px`);
    }

}
