import { TiledPicture } from "./TiledPicture";
import LocalEventManager from "./LocalEventManager";
// import LZString from 'lz-string';
import { getArtwork, checkAvailibility } from "./service/services";
import { RequestForm } from "./RequestForm";
import { TileSelector } from "./TileSelector";

const fixSizes = () => {
    const tileContainer = document.querySelector('.tiled-picture-container') as HTMLElement;
    const requestContainer = document.querySelector('.request-container') as HTMLElement;
    const paintBrushContainer = document.querySelector('.paint-brush-container') as HTMLElement;
    const isPortrait = window.innerWidth < window.innerHeight;
    const navbarSize = 50;
    if (isPortrait) {

    } else {
        // requestContainer!.style.width = (window.innerWidth - tileContainer.offsetWidth) + 'px';
        // paintBrushContainer!.style.height = (window.innerHeight - requestContainer.offsetHeight - 40) + 'px'
    }
}

export default async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const id = 'de70342d-f77a-4279-a05c-fd7a2a2b8a45'
    const state = urlSearchParams.get('state');

    try {
        if (!id) throw new Error('No id provided');
        // TODO: show loader
        const data = await getArtwork(id);
        const localEventManager = new LocalEventManager();
        const tiledPicture = new TiledPicture(data, localEventManager);
        tiledPicture.mount();

        // const uiContainer = document.createElement('div');
        // document.getElementById('transhuman-app')?.appendChild(uiContainer);

       /*  const requestForm = new RequestForm(id, localEventManager);
        requestForm.mount();
        setTimeout(() => { 
            fixSizes();
            if (requestForm.container) {
                requestForm.container.style.opacity = '1';
            }
        }, 600); */
        const tileSelector = new TileSelector(tiledPicture, localEventManager);
        tileSelector.mount();
        tileSelector.selectTile(0, 1);
        // tiledPicture.setPaintBrushMode(true);
        window.addEventListener('resize', fixSizes);
        if (state) {
            // requestForm.code = state;
            // const decodedState = LZString.decompressFromEncodedURIComponent(state);
            // if (!decodedState) throw new Error('Invalid state');
            tiledPicture.decodeState(state);
            // const availibility = await checkAvailibility(id, state);
            // requestForm.setAvailibility(availibility, state);
        } else {
            const url = new URL(window.location.href);
            const state = tiledPicture.encodeState();
            url.searchParams.set('state', state);
            window.history.pushState({}, '', url.toString());
            // requestForm.code = state;
            // const availibility = await checkAvailibility(id, state);
            // requestForm.setAvailibility(availibility, state);
        }
        let timeout: number | undefined;
        let index = 0;
        localEventManager.subscribe('tile', (payload) => {
            // open selector
            // localEventManager.publish('tile-change', payload);
            tileSelector.selectTile(payload.row, payload.col);
        });
        localEventManager.subscribe('tile-change', (payload) => {
            clearTimeout(timeout);
            let time = 1000;
            if (Array.isArray(payload)) {
                time = 100 + (100 * payload.length);
            }
            timeout = setTimeout(async () => {
                index++;
                const url = new URL(window.location.href);
                const state = tiledPicture.encodeState();
                // const compressedState = LZString.compressToEncodedURIComponent(state);
                url.searchParams.set('state', state);
                window.history.pushState({}, '', url.toString());
                // requestForm.code = state;

                // const availibility = await checkAvailibility(id, state);
                // requestForm.setAvailibility(availibility, state);
            }, time);
        });

        // TODO: move in separate class
        // const checkButton = document.createElement('button');
        // checkButton.textContent = 'CHECK';
        // checkButton.classList.add('check-button');
        // document.getElementById('transhuman-app')?.append(checkButton);
    } catch (error) {
        console.error(error);
        // TODO: handle error
    }

}
