import { TiledPicture } from "./TiledPicture";
import { EventManagerInterface } from "./eventManager";
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

export class TileSelector {

    element: HTMLElement;
    eventManager: EventManagerInterface;
    tiledPicture: TiledPicture;
    code: string | null = null;
    paintBrushSelectors: HTMLButtonElement[] = [];
    selectedTile: { row: number, col: number } | { row: number, col: number }[] | null = null;
    dialog: HTMLDialogElement;
    container: HTMLDivElement;
    mode: 'single-cell' | 'half-and-half' | 'quarter' = 'single-cell';
    swiper: Swiper;

    constructor(tiledPicture: TiledPicture, eventManager: EventManagerInterface) {
        this.tiledPicture = tiledPicture;
        this.eventManager = eventManager;
    }

    renderPaintBrushSelector(index) {
        let _url = this.tiledPicture.urls[index];
        if (this.tiledPicture.paintBrushUrls && this.tiledPicture.paintBrushUrls[index]) {
            _url = this.tiledPicture.paintBrushUrls[index]!;
        }
        return `
            <button
                class="paint-brush-selector"
                style="background-image: url(${_url}?width=100&height=100&format=jpeg)"
                data-index="${index}"
                ></button>
        `;
    }
    /*

    ${this.tiledPicture.urls.map((url, index) => {
                    let _url = url;
                    if (this.tiledPicture.paintBrushUrls && this.tiledPicture.paintBrushUrls[index]) {
                        _url = this.tiledPicture.paintBrushUrls[index]!;
                    }
                    return `
                        <button
                            class="paint-brush-selector"
                            style="background-image: url(${_url}?width=400&height=400)"
                            data-index="${index}"
                            ></button>
                    `}).join('')}
    */

    render() {
        return `
            <div style="height: auto; display: flex; align-items: center;">
                <button id="info-button" onclick="document.getElementById('info-show').showModal()">
                </button>
                <a href="#" class="button" id="previewButton">
                    <span class="it">VISUALIZZA</span>
                    <span class="en">SHOW</span>
                </a>
            </div>
            <div class="paint-brush-selector-container">
                <div class="swiper-pagination"></div>
                <div class="swiper">
                    <div class="swiper-wrapper">
                    ${this.tiledPicture.urls.map((url, index) => {
                        let result = '';
                        if (index % 18 === 0) result += '<div class="swiper-slide">';
                        result += this.renderPaintBrushSelector(index);
                        if (index % 18 === 17) result += '</div>';
                        return result;
                    }).join('')}
                    </div>
                </div>
            </div>
        `;
    }

    mount(element?: HTMLElement | string) {
        if (element === undefined) {
            element = document.querySelector('#transhuman-app')! as HTMLElement;
        }
        if (typeof element === 'string') {
            element = document.querySelector(element)! as HTMLElement;
        }

        const dialog = document.createElement('dialog') as HTMLDialogElement;
        dialog.id = 'tile-selector';

        dialog.innerHTML = `
            <form method="dialog"><button class="close">X</button></form>
        `;

        const container = document.createElement('div');

        container.classList.add('paint-brush-container');

        /* container.addEventListener('mousedown', (e) => {
            e.stopPropagation();
        }); */

        container.innerHTML = this.render();

        /* this.tiledPicture.urls.forEach((url, index) => {
            const button = document.createElement('button');
            button.classList.add('paint-brush-selector');
            let _url = url;
            if (this.tiledPicture.paintBrushUrls && this.tiledPicture.paintBrushUrls[index]) {
                _url = this.tiledPicture.paintBrushUrls[index]!;
            }
            button.style.backgroundImage = `url(${_url}?width=400&height=400)`;
            button.addEventListener('click', (e) => {
                // e.stopPropagation();
                if (this.mode === 'single-cell') {
                    this.tiledPicture.eventManager.publish('tile-change', { ...this.selectedTile, state: index });
                } else {
                    if (Array.isArray(this.selectedTile)) {
                        this.tiledPicture.eventManager.publish('tile-change', this.selectedTile.map((t) => ({
                            row: t.row,
                            col: t.col,
                            state: index
                        })))
                    }
                }
            });
            this.paintBrushSelectors.push(button);
            container.appendChild(button);
        }); */

        container.querySelectorAll('.paint-brush-selector').forEach((button) => {
            button.addEventListener('click', () => {
                if (this.mode === 'single-cell') {
                    this.tiledPicture.eventManager.publish('tile-change', { ...this.selectedTile, state: button.dataset.index });
                } else {
                    if (Array.isArray(this.selectedTile)) {
                        this.tiledPicture.eventManager.publish('tile-change', this.selectedTile.map((t) => ({
                            row: t.row,
                            col: t.col,
                            state: button.dataset.index
                        })))
                    }
                }
            });
        });

        // container.classList.add('closed');
        element.appendChild(container);
        // dialog.showModal();
        this.element = element;
        this.container = container;
        // this.dialog = dialog;
        this.swiper = new Swiper('.swiper', {
            modules: [Pagination],
           /*  cssMode: true, */
            focusableElements: undefined,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
              },            
         });

        /* window.addEventListener('mousedown', () => {
            this.selectedTile = null;
            container.classList.add('closed');
            this.tiledPicture.setTileSize();
            this.tiledPicture.tiles.forEach(row => {
                row.forEach(tile => {
                    tile.el.classList.remove('selected');
                });
            });
        }); */

        this.eventManager.subscribe('tile-change', (payload) => {
            const state = this.tiledPicture.encodeState();
            document.getElementById('previewButton')?.setAttribute('href', `/?mode=preview&state=${state}&locale=${window.locale}`);
        });
        const state = this.tiledPicture.encodeState();
        document.getElementById('previewButton')?.setAttribute('href', `/?mode=preview&state=${state}&locale=${window.locale}`);
    }

    selectTile(row: number, col: number) {

        if (this.mode === 'single-cell') {
            this.selectedTile = { row, col };
            this.container.classList.remove('closed');
            // remove selected from all tiles
            this.tiledPicture.tiles.forEach(row => {
                row.forEach(tile => {
                    tile.el.classList.remove('selected');
                });
            });
            this.tiledPicture.setTileSize();
            this.tiledPicture.tiles[row][col].el.classList.add('selected');
        }

        if (this.mode === 'half-and-half') {
            this.selectedTile = [];
            let selectedHorizontalHalf = 'left';
            if (col >= this.tiledPicture.cols / 2) selectedHorizontalHalf = 'right';

            for (let r = 0; r < this.tiledPicture.rows; r++) {
                for (let c = 0; c < this.tiledPicture.cols; c++) {
                    if (selectedHorizontalHalf === 'left') {
                        if (c < this.tiledPicture.cols / 2) {
                            this.selectedTile.push({ row: r, col: c });
                            this.tiledPicture.tiles[r][c].el.classList.add('selected');
                        } else {
                            this.tiledPicture.tiles[r][c].el.classList.remove('selected');
                        }
                    } else {
                        if (c < this.tiledPicture.cols / 2) {
                            this.tiledPicture.tiles[r][c].el.classList.remove('selected');
                        } else {
                            this.selectedTile.push({ row: r, col: c });
                            this.tiledPicture.tiles[r][c].el.classList.add('selected');
                        }
                    }
                }
            }
        }

        if (this.mode === 'quarter') {
            this.selectedTile = [];
            let selectedHorizontalQuarter = 'left';
            let selectedVerticalQuarter = 'top';
            if (col >= this.tiledPicture.cols / 2) selectedHorizontalQuarter = 'right';
            if (row >= this.tiledPicture.rows / 2) selectedVerticalQuarter = 'bottom';

            for (let r = 0; r < this.tiledPicture.rows; r++) {
                for (let c = 0; c < this.tiledPicture.cols; c++) {
                    if (selectedHorizontalQuarter === 'left') {
                        if (c < this.tiledPicture.cols / 2) {
                            if (selectedVerticalQuarter === 'top') {
                                if (r < this.tiledPicture.rows / 2) {
                                    this.selectedTile.push({ row: r, col: c });
                                    this.tiledPicture.tiles[r][c].el.classList.add('selected');
                                } else {
                                    this.tiledPicture.tiles[r][c].el.classList.remove('selected');
                                }
                            } else {
                                if (r < this.tiledPicture.rows / 2) {
                                    this.tiledPicture.tiles[r][c].el.classList.remove('selected');
                                } else {
                                    this.selectedTile.push({ row: r, col: c });
                                    this.tiledPicture.tiles[r][c].el.classList.add('selected');
                                }
                            }
                        } else {
                            this.tiledPicture.tiles[r][c].el.classList.remove('selected');
                        }
                    } else {
                        if (c < this.tiledPicture.cols / 2) {
                            this.tiledPicture.tiles[r][c].el.classList.remove('selected');
                        } else {
                            if (selectedVerticalQuarter === 'top') {
                                if (r < this.tiledPicture.rows / 2) {
                                    this.selectedTile.push({ row: r, col: c });
                                    this.tiledPicture.tiles[r][c].el.classList.add('selected');
                                } else {
                                    this.tiledPicture.tiles[r][c].el.classList.remove('selected');
                                }
                            } else {
                                if (r < this.tiledPicture.rows / 2) {
                                    this.tiledPicture.tiles[r][c].el.classList.remove('selected');
                                } else {
                                    this.selectedTile.push({ row: r, col: c });
                                    this.tiledPicture.tiles[r][c].el.classList.add('selected');
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}