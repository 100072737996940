import { Directus, DefaultItem } from "@directus/sdk";
import { CustomDirectusTypes, Requests } from "./types";
import { tiledPictureOptions } from "../tiledPictureOptions";

const directus = new Directus<CustomDirectusTypes>(process.env.DIRECTUS_URL!);

export const getArtwork = async (id: string): Promise<tiledPictureOptions> => {
    if (!id) throw new Error("No id provided");
    const data = await directus.items('artworks').readOne(id, {
        fields: ['*', 'images.*'],
        deep: {
            images: {
                _limit: -1
            }
        }
    });

    if (!data) throw new Error("No data found");
    document.title = data.name || '';
    const result = {
        id: data.id,
        rows: data.rows!,
        cols: data?.columns!,
        urls: data.images!.map((image) => `${process.env.DIRECTUS_URL}/assets/${image.directus_files_id}`),
        paintBrushUrls: data.images!.map((image) => image.icon ? `${process.env.DIRECTUS_URL}/assets/${image.icon}` : null),
        frameUrls: data.images!.map((image) => image.frame ? `${process.env.DIRECTUS_URL}/assets/${image.frame}` : null),
    }
    return result;
}

export const getRequest = async (id: string): Promise<Requests> => {
    if (!id) throw new Error("No id provided");
    const data = await directus.items('requests').readOne(id);
    if (!data) throw new Error("No data found");
    const result = {
        id: data.id,
        email: data.email!,
        message: data.message!,
        code: data.code!,
        artwork: data.artwork,
        status: data.status!
    }
    return result;
}

export const updateRequest = async (request: Requests): Promise<void> => {
    if (!request.id) throw new Error("No id provided");
    const data = await directus.items('requests').updateOne(request.id, {
        phone: request.phone,
        name: request.name
    });
}

export const requestCombination = async (
    { email, message, code, artwork, material, newsletter, locale }: {
        email: string,
        message: string,
        code: string,
        artwork: string,
        material: string,
        newsletter: 'on' | 'off',
        locale: string
    }
): Promise<void> => {

    const response = await directus.items('requests').createOne({
        email,
        message,
        code,
        artwork,
        material,
        newsletter: newsletter === 'on' ? true : false,
        locale
    });

}

export const checkAvailibility = async (artwork: string, code: string): Promise<'pending' | 'available' | 'unavailable'> => {
    const response = await directus.items('requests').readByQuery({
        filter: {
            code: { _eq: code },
            artwork: { _eq: artwork },
            status: { _in: ['pending', 'confirmed'] }
        }
    });
    if (response?.data && response.data.length > 0) {
        // if there is at least a request with the status confirmed return unavailable
        if (response.data.some((request) => request.status === 'confirmed')) return 'unavailable';
        // if there is at least a request with the status pending return pending
        if (response.data.some((request) => request.status === 'pending')) return 'pending';
    }

    return 'available';
}