abstract class EncoderImplementation {

    value: string;
    encoderByte: string = '';

    abstract encode(number: number)
    abstract decode(): number
    
    constructor (value?: string) {
        this.value = value || this.encoderByte;
    }
}

class ModifiedBase64Encoder extends EncoderImplementation {
    static alphabet: string = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz+-$';
    index: number = 1;
    encoderByte = '0'

    static encode(number: number): string {
        if (number > ModifiedBase64Encoder.alphabet.length - 1) {
            // use multiple characters
            const first = Math.floor(number / ModifiedBase64Encoder.alphabet.length);
            const second = number % ModifiedBase64Encoder.alphabet.length;
            return '.' + ModifiedBase64Encoder.alphabet[first] + ModifiedBase64Encoder.alphabet[second];
        }

        return ModifiedBase64Encoder.alphabet[number];
    }
    
    encode(number: number) {
        this.value += ModifiedBase64Encoder.encode(number);
    }
    
    decode(): number {
        if (this.value[this.index] === '.') {
            const first = ModifiedBase64Encoder.alphabet.indexOf(this.value[this.index + 1]);
            const second = ModifiedBase64Encoder.alphabet.indexOf(this.value[this.index + 2]);
            this.index += 3;
            return first * ModifiedBase64Encoder.alphabet.length + second;
        }

        const result = ModifiedBase64Encoder.alphabet.indexOf(this.value[this.index]);
        this.index++;
        return result;
    }

}

class EmojiEncoder extends EncoderImplementation {
    index: number = -2;
    encoderByte = ''
    static letters = ['A', 'B', 'C', 'D']; 

    encode(number: number) {
        if (this.index < 0) {
            this.index++;
            return;
        }
        if (this.index > 0) this.value += "-";
        this.value += (number + 1).toString().padStart(3, '0');
        this.value += EmojiEncoder.letters[this.index % 4];
        this.index++;
    }

    decode():number {
        if (this.index < 0) {
            this.index++;
            return 2;
        }
        const start = this.index * 5;
        const end = start + 4;
        this.index++;
        return parseInt(this.value.substring(start, end), 10) - 1;
    }
}


class Encoder {
   
    encoder: EncoderImplementation;

    constructor( { value, id } : { value?: string, id?: string }) {

        // basare encoder-decoder sul numero di celle
        switch(id) {
            case 'de70342d-f77a-4279-a05c-fd7a2a2b8a45':
            this.encoder = new EmojiEncoder(value);
            break;
            default:
            this.encoder = new ModifiedBase64Encoder(value || '0');
        }
        
    }
    
    encode(number: number) {
        this.encoder.encode(number);
    }
    
    decode(): number {
        return this.encoder.decode();
    }

    get value () {
        return this.encoder.value;
    }

}

export default Encoder;